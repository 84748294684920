@font-face {
  font-family: 'sudregular';
  src: url('sud-webfont.woff2') format('woff2'),
    url('sud-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'radikalblack';
  src: url('nootype_-_radikal_black-webfont.woff2') format('woff2'),
    url('nootype_-_radikal_black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'radikalbold_italic';
  src: url('nootype_-_radikal_bold_italic-webfont.woff2') format('woff2'),
    url('nootype_-_radikal_bold_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'radikalbold';
  src: url('nootype_-_radikal_bold-webfont.woff2') format('woff2'),
    url('nootype_-_radikal_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'radikalitalic';
  src: url('nootype_-_radikal_italic-webfont.woff2') format('woff2'),
    url('nootype_-_radikal_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'radikallight_italic';
  src: url('nootype_-_radikal_light_italic-webfont.woff2') format('woff2'),
    url('nootype_-_radikal_light_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'radikallight';
  src: url('nootype_-_radikal_light-webfont.woff2') format('woff2'),
    url('nootype_-_radikal_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'radikalmedium_italic';
  src: url('nootype_-_radikal_medium_italic-webfont.woff2') format('woff2'),
    url('nootype_-_radikal_medium_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'radikalmedium';
  src: url('nootype_-_radikal_medium-webfont.woff2') format('woff2'),
    url('nootype_-_radikal_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'radikalthin_italic';
  src: url('nootype_-_radikal_thin_italic-webfont.woff2') format('woff2'),
    url('nootype_-_radikal_thin_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'radikalthin';
  src: url('nootype_-_radikal_thin-webfont.woff2') format('woff2'),
    url('nootype_-_radikal_thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'radikalregular';
  src: url('nootype_-_radikal-webfont.woff2') format('woff2'),
    url('nootype_-_radikal-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'radikalblack_italic';
  src: url('nootype_-_radikal_black_italic-webfont.woff2') format('woff2'),
    url('nootype_-_radikal_black_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}